<template>
  <div id="content" class="masterFees">
    <!-- breadCrumb -->
    <v-breadcrumbs :items="breadcrumbList" divider="/">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <!-- breadCrumb -->
    <div class="master" id="master">
      <v-row>
        <v-col cols="6" sm="6" md="6">
          <h2 class="main-color">{{ $t("Fee Plan") }} - {{ planName }}</h2>
        </v-col>
        <v-col cols="6" sm="6" md="6" class="text-right">
          <div class="activate-btns">
            <v-btn
              class="confirm primary"
              @click="printPage"
              v-if="
                confirm && printBtnVisible && !masterEditable && !gradeEditable
              "
            >
              {{ $t("Print") }}
            </v-btn>
            <v-btn
              class="confirm success"
              v-if="
                !confirm &&
                  changeConfirmBtnVisible &&
                  !masterEditable &&
                  !gradeEditable
              "
              @click="confirmPlan"
              :loading="confirmLoading"
              :disabled="confirmLoading"
            >
              {{ $t("Confirm") }}
            </v-btn>
            <v-btn
              class="confirm success"
              v-if="
                confirm &&
                  changeConfirmBtnVisible &&
                  !masterEditable &&
                  !gradeEditable
              "
              @click="confirmPlan"
              :loading="confirmLoading"
              :disabled="confirmLoading"
            >
              {{ $t("unConfirm") }}
            </v-btn>
            <v-btn
              class="activate primary"
              v-if="!activate && !masterEditable && !gradeEditable"
              @click="activatePlan"
              :disabled="!confirm || activateLoading"
              :loading="activateLoading"
              v-can="'change-plan-default-status-fee-plan'"
              >{{ $t("Activate") }}</v-btn
            >
            <v-btn
              class="activate primary"
              v-if="activate && !masterEditable && !gradeEditable"
              @click="activatePlan"
              v-can="'change-plan-default-status-fee-plan'"
              :disabled="activateLoading"
              :loading="activateLoading"
              >{{ $t("Deactivate") }}</v-btn
            >
          </div>
        </v-col>
      </v-row>

      <v-row class="padding-top">
        <v-col cols="6" sm="6">
          <div class=" masterTitle" id="masterTitle">
            <h3 class="header main-color">{{ $t("Master Fees") }}</h3>
          </div>
        </v-col>
        <v-col cols="6" sm="6">
          <div class="text-right  icon-section">
            <v-icon
              v-if="
                !masterEditable &&
                  masterItemsData.length > 0 &&
                  editMasterBtnVisible
              "
              @click="editMaster"
              :class="{ hidden: activate || confirm }"
              class="edit-icon"
            >
              edit
            </v-icon>
            <v-icon
              class="main-color"
              large
              v-if="!confirm && !masterEditable && addMasterBtnVisible"
              @click="openMasterDialog"
              >add_circle</v-icon
            >
          </div>
        </v-col>
        <hr />
      </v-row>

      <!-- ======================== master table ====================================== -->
      <v-form v-model="masterFormValid" ref="masterTableForm" id="masterForm">
        <table class="text-center table master-table">
          <thead>
            <tr>
              <td></td>
              <td class="text-center main-color header">
                {{ $t("Value") }}
              </td>
              <td class="text-center main-color header">
                {{ $t("Due Date") }}
              </td>
              <td>
                <v-checkbox
                  v-if="masterEditable"
                  label="mandatory"
                  @change="makeAllMastersMandatory()"
                  v-model="allMasterMandatory"
                ></v-checkbox>
              </td>
            </tr>
          </thead>
          <tr
            v-for="(item, index) in masterItemsData"
            :key="index"
            class="data-row"
          >
            <td class="master_items" v-if="item.item">
              {{ item.item.name }}
            </td>
            <td>
              <v-text-field
                label="Solo"
                solo
                dense
                placeholder="Enter value"
                class="input-with-icon-right"
                v-if="masterEditable"
                v-model="item.price"
                :rules="feesValidation"
              ></v-text-field>

              <p v-if="!masterEditable">{{ item.price }}</p>
            </td>
            <td>
              <v-text-field
                label="Solo"
                solo
                dense
                placeholder="Enter due date"
                class="input-with-icon-right"
                v-if="masterEditable && !item.item.static"
                autocomplete="off"
                :id="idString(item.id)"
                v-model="item.due_date"
                append-outer-icon="calendar_today"
                :rules="rules.required"
                @keydown.prevent
              ></v-text-field>

              <p v-if="!masterEditable && item.due_date" class="due_date">
                <span class="date-icon"
                  ><i class="far fa-calendar success--text"></i
                ></span>
                <span v-if="!item.item.static">{{ item.due_date }} </span>
              </p>
              <p class="due_date">
                <span v-if="item.item.static">{{
                  $t("Immediate Payment")
                }}</span>
              </p>
            </td>
            <td>
              <v-checkbox
                v-if="masterEditable"
                label="mandatory"
                v-model="item.is_mandatory"
                @change="checkMandatoryForMasters()"
              ></v-checkbox>
              <v-icon v-if="item.is_mandatory && !masterEditable"
                >check_circle_outline</v-icon
              >
              <v-icon v-if="!item.is_mandatory && !masterEditable"
                >cancel</v-icon
              >
            </td>
          </tr>
          <tr class="data-row">
            <td class="total">{{ $t("Total") }}</td>
            <td>{{ masterTotal }}</td>
            <td></td>
          </tr>
        </table>
      </v-form>

      <v-row>
        <v-col
          sm="12"
          cols="12"
          class="text-right"
          v-if="masterItemsData.length > 0"
        >
          <v-btn
            class="modal-btn-cancel cancel-edit"
            v-if="masterEditable"
            @click="cancelMasterUpdate"
          >
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn
            class="modal-btn-save"
            v-if="masterEditable"
            @click="updateMaster"
            :loading="masterLoading"
            :disabled="masterLoading"
          >
            {{ $t("Save") }}
          </v-btn>
        </v-col>
      </v-row>

      <!-- ============================details Fee plan================================= -->
      <v-row class="grade-header" v-can="'list-grade-fee-plans'">
        <v-col cols="6" sm="6">
          <div class="gradeTitle" id="gradeTitle">
            <h3 class="header main-color">{{ $t("Grade Fees") }}</h3>
          </div>
        </v-col>
        <v-col cols="6" sm="6">
          <div class=" text-right icon-section">
            <v-icon
              v-if="
                !gradeEditable &&
                  gradeItemsData.length > 0 &&
                  editMasterGradeBtnVisible
              "
              @click="editGrade"
              :class="{ hidden: activate || confirm }"
              class="edit-icon"
            >
              edit
            </v-icon>
            <v-icon
              class=" main-color"
              large
              @click="openGradeDialog"
              v-if="!confirm && !gradeEditable && addMasterGradeBtnVisible"
              >add_circle</v-icon
            >
          </div>
        </v-col>
        <hr />
      </v-row>

      <v-form v-model="gradeFormValid" ref="gradeTableForm" id="gradeTableForm">
        <v-row class="padding-top">
          <v-col cols="8" sm="9" md="10" class="grades-itemsgrid">
            <div class="table-container">
              <table class="table details-table">
                <tr class="header-row">
                  <td class="header text-center">{{ $t("Grades") }}</td>
                  <span v-for="(header, i) in detailsHeaders" :key="i">
                    <td class="td-item main-color header text-center">
                      {{ header.item.name }}
                      <v-checkbox
                        v-if="gradeEditable"
                        v-model="allGradeItemsModels[header.item.id]"
                        label="mandatory"
                        @change="makeAllGradeMandatory(header.item.id)"
                      ></v-checkbox>
                    </td>
                  </span>
                </tr>
                <tr class="data-row" v-for="(grade, i) in allGrades" :key="i">
                  <td class=" grade_cell text-center">
                    <p>{{ grade.grade_name }}</p>
                    <p class="main-color grade-due-date">
                      {{ $t("Due date") }}
                    </p>
                    <v-icon
                      class="main-color"
                      v-if="
                        !activate &&
                          !confirm &&
                          !gradeEditable &&
                          deleteGradeBtnVisible
                      "
                      @click="openConfirmDialog(grade.id)"
                      >delete</v-icon
                    >
                  </td>
                  <span
                    v-for="(item, index) in gradeItemsData"
                    :key="index"
                    class="cell-item"
                  >
                    <td
                      v-if="item.grade_id == grade.id"
                      class="td-item text-center background-white"
                    >
                      <p v-if="!gradeEditable && item.price">
                        {{ item.price }}
                      </p>
                      <p
                        v-if="!gradeEditable && !item.price"
                        style="visibility: hidden"
                      >
                        {{ $t("price") }}
                      </p>
                      <p
                        v-if="!gradeEditable && item.due_date"
                        class="due_date"
                      >
                        <span class="date-icon"
                          ><i class="far fa-calendar success--text"></i
                        ></span>
                        <span>{{ item.due_date }}</span>
                      </p>
                      <p
                        v-if="!gradeEditable && !item.due_date"
                        class="due_date"
                        style="visibility: hidden"
                      >
                        <span class="date-icon"
                          ><i class="far fa-calendar success--text"></i
                        ></span>
                        <span>32/32/3223</span>
                      </p>

                      <v-icon
                        v-if="
                          item.is_mandatory && !gradeEditable && item.due_date
                        "
                        >check_circle_outline</v-icon
                      >
                      <v-icon
                        v-if="
                          !item.is_mandatory && !gradeEditable && item.due_date
                        "
                        >cancel</v-icon
                      >

                      <v-text-field
                        solo
                        dense
                        placeholder="Enter value"
                        class="input-with-icon-right"
                        v-if="gradeEditable"
                        v-model="item.price"
                        :rules="feesValidation"
                      ></v-text-field>

                      <v-text-field
                        solo
                        dense
                        class="input-with-icon-right"
                        :placeholder="$t('Enter due date')"
                        v-if="gradeEditable"
                        :id="item.due_date_id"
                        v-model="item.due_date"
                        append-outer-icon="calendar_today"
                        autocomplete="off"
                        :rules="rules.required"
                        @keydown.prevent
                      ></v-text-field>
                      <v-checkbox
                        v-if="gradeEditable"
                        label="mandatory"
                        v-model="item.is_mandatory"
                        @change="checkMandatoryForGrades(item.item_id)"
                      ></v-checkbox>
                    </td>
                  </span>
                </tr>
              </table>
            </div>
          </v-col>
          <v-col cols="4" sm="3" md="2" class="total-container">
            <table class="table details-table total-table">
              <tr class="header-row">
                <span>
                  <td
                    class=" text-center td-item header"
                    style="width: 100px !important;
                              border-radius: 0;"
                  >
                    {{ $t("Total") }}
                  </td>
                </span>
              </tr>
              <tr class="data-row" v-for="(grade, i) in allGrades" :key="i">
                <span v-for="total in gradeTotalHeader" :key="total.name">
                  <td
                    v-if="total.id == grade.id"
                    class="grade-total text-center background-white"
                    :class="{ totalHeight: gradeEditable }"
                  >
                    <p v-if="total.total">
                      {{ total.total }}
                    </p>
                    <p v-if="!total.total"></p>
                    <!-- <p style="visibility: hidden;">da</p> -->
                    <p
                      style="visibility: hidden;"
                      v-if="
                        !activate &&
                          !confirm &&
                          !gradeEditable &&
                          deleteGradeBtnVisible
                      "
                    >
                      da
                    </p>
                  </td>
                </span>
              </tr>
            </table>
          </v-col>
        </v-row>
      </v-form>

      <v-row v-if="!activate && !confirm && !gradeEditable">
        <v-col cols="12" sm="12" class="text-center">
          <v-btn class="modal-btn-save" @click="openAddGradesModal">{{
            $t("Add New Grade")
          }}</v-btn>
        </v-col>
      </v-row>

      <div class="btn-div text-right" v-if="gradeItemsData.length > 0">
        <v-btn
          class="modal-btn-cancel cancel-edit"
          v-if="gradeEditable"
          @click="cancelGradeUpdate"
        >
          {{ $t("Cancel") }}
        </v-btn>
        <v-btn
          class="modal-btn-save"
          v-if="gradeEditable"
          @click="updateGrade"
          :loading="gradeLoading"
          :disabled="gradeLoading"
        >
          {{ $t("Save") }}
        </v-btn>
      </div>

      <v-dialog v-model="masterDialog" persistent width="500">
        <v-card style="">
          <v-card-text>
            <h3 class="modal-header">{{ $t("Add Master Fees") }}</h3>
            <v-col cols="12" sm="12">
              <v-form v-model="masterItemsFormValid" ref="addMasterItemsForm">
                <label>{{ $t("Master Items") }}</label>
                <v-autocomplete
                  :items="allMasterItems"
                  v-model="masterItems"
                  item-text="name"
                  item-value="id"
                  :rules="[checkMultiSelect(masterItems)]"
                  chips
                  solo
                  multiple
                ></v-autocomplete>
              </v-form>
            </v-col>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="modal-btn-cancel" @click="masterCancel">
              {{ $t("Cancel") }}
            </v-btn>
            <v-btn class="modal-btn-save" @click="saveMaster">
              {{ $t("Save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        persistent
        class="details-dialog"
        id="details-dialog"
        v-model="detailsDialog"
        width="500"
      >
        <v-card>
          <v-card-text>
            <h3 class="modal-header">{{ $t("Add Grades Fees") }}</h3>
            <v-form v-model="gradeItemsFormValid" ref="addGradeItemsForm">
              <v-col class="d-flex" cols="12" sm="12">
                <v-autocomplete
                  :items="allGradeItems"
                  v-model="gradeItems"
                  :rules="[checkMultiSelect(gradeItems)]"
                  item-text="name"
                  item-value="id"
                  chips
                  solo
                  multiple
                ></v-autocomplete>
              </v-col>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="modal-btn-cancel" @click="gradeCancel">
              {{ $t("Cancel") }}
            </v-btn>
            <v-btn class="modal-btn-save" @click="saveGrade">
              {{ $t("Save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="confirmDialog"
        persistent
        max-width="300"
        class="dialog_confirm"
      >
        <v-card>
          <v-card-title class="headline"
            >{{ $t("Confirmation Message") }}
          </v-card-title>

          <v-card-text
            >{{ $t("Are you sure you want to permanently remove this grade") }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn class="modal-btn-cancel" @click="confirmDialog = false">
              {{ $t("No") }}
            </v-btn>
            <v-btn
              @click="deleteGradeFromFeePlan(deletedgradeId)"
              class="modal-btn-save"
              :loading="loading"
              :disabled="loading"
            >
              {{ $t("Yes") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-snackbar
      v-model="snackbar"
      :color="snackColor"
      class="white--text"
      :top="true"
      :right="true"
      :timeout="timeout"
    >
      {{ snackText }}
      <v-btn color="pink" text @click="snackbar = false">
        {{ $t("Close") }}
      </v-btn>
    </v-snackbar>

    <!-- add new grades components -->
    <AddNewGrades
      :addGradeDialog="addGradeDialog"
      :selectedGrades="allGrades"
      :planId="planId"
      v-on:childToParent="closeAddGradesDialog"
    ></AddNewGrades>
  </div>
</template>
<script>
import axios from "axios";
import ACL from "../../acl";
import AddNewGrades from "../../components/modals/addNewGrades.vue";
import { validationMixin } from "../../mixins/validationMixin";

export default {
  name: "MasterFees",
  mixins: [validationMixin],
  components: { AddNewGrades },
  data() {
    return {
      activateLoading: false,
      confirmLoading: false,
      masterLoading: false,
      gradeLoading: false,
      allGradeItemsModels: [],
      gradeAdded: false,
      loading: false,
      addGradeDialog: false,
      confirmDialog: false,
      deletedgradeId: "",

      printBtnVisible: ACL.checkPermission("print-fee-plans"),
      changeConfirmBtnVisible: ACL.checkPermission(
        "change-plan-confirmation-status-fee-paln"
      ),
      editMasterBtnVisible: ACL.checkPermission("edit-master-fee-plans"),
      editMasterGradeBtnVisible: ACL.checkPermission("edit-grades-fee-plans"),
      addMasterBtnVisible: ACL.checkPermission("add-master-fee-plans"),
      addMasterGradeBtnVisible: ACL.checkPermission("add-grade-fee-plans"),
      deleteGradeBtnVisible: ACL.checkPermission("delete-grade-fee-plans"),
      allGradesMandatory: false,
      allMasterMandatory: false,
      masterDialog: false,
      detailsDialog: false,
      masterItemsData: [],
      masterItems: [],
      allMasterItems: [],
      masterEditable: false,
      gradeEditable: false,
      masterTotal: "",
      planId: "",
      planName: "",
      confirm: "",
      activate: "",
      // details table data
      gradeItemsData: [],
      gradeItems: [],
      allGradeItems: [],
      gradeIds: [1, 2, 3, 4, 5],
      allGrades: [],
      detailsHeaders: [],
      gradeTotalHeader: [],

      // snackbar
      snackbar: false,
      snackText: "",
      snackColor: "success",
      timeout: 6000,

      // table validation
      gradeFormValid: false,
      masterFormValid: false,

      // dialog Validation
      masterItemsFormValid: false,
      gradeItemsFormValid: false,
      rules: {
        required: [
          (value) => !!value || this.$i18n.t("This field is required"),
        ],
      },

      feesValidation: [
        (v) => !!v || this.$i18n.t("This field is required"),
        (v) =>
          (v && /^\d+$/.test(v)) ||
          this.$i18n.t("This field only accept numbers"),
        // v => v != 0 || "Value should be > 0"
      ],

      /* master Data */
      masterData: {
        authourization_number: "",
        master_language: 1,
        other_language: 0,
        tel: "",
        fax: "",
        mobile: "",
        email: "",
        website: "",
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
        youtube_link: "",
        logo: "",
        en: {
          name: "",
          address: "",
        },
        ar: {
          name: "",
        },
      },
    };
  },
  watch: {
    // masterEditable: {
    //   handler() {
    //     if (this.masterEditable == true) {
    //       this.allMasterMandatory = this.checkMandatoryForMasters();
    //       console.log(this.allMasterMandatory);
    //     }
    //   }
    // },
    gradeEditable: {
      handler() {
        if (this.gradeEditable == true) {
          this.getGradeHeadersModels();
        }
      },
    },
  },
  methods: {
    getGradeHeadersModels() {
      // console.log(this.allGradeItemsModels);
      this.detailsHeaders.forEach((item) => {
        let exist = false;
        for (let i = 0; i < this.allGradeItemsModels.length; i++) {
          if (i == item.item_id) {
            exist = true;
            break;
          }
        }

        if (exist == true) {
          this.checkMandatoryForGrades(item.item_id);
        } else {
          this.allGradeItemsModels[item.item_id] = false;
          this.checkMandatoryForGrades(item.item_id);
        }
        // this.makeAllGradeMandatory(item.item_id);
      });
    },
    makeAllMastersMandatory() {
      if (this.allMasterMandatory == true) {
        this.masterItemsData.forEach((item) => {
          item.is_mandatory = true;
        });
      } else {
        this.masterItemsData.forEach((item) => {
          item.is_mandatory = false;
        });
      }
    },

    checkMandatoryForMasters() {
      let mandatory = false;
      let notMandatory = false;
      this.masterItemsData.forEach((item) => {
        if (item.is_mandatory === true || item.is_mandatory === 1) {
          mandatory = true;
        } else {
          notMandatory = true;
          // return false;
        }
      });
      if (notMandatory == true) {
        this.allMasterMandatory = false;
        return this.allMasterMandatory;
      } else {
        this.allMasterMandatory = mandatory;
        return this.allMasterMandatory;
      }
    },

    makeAllGradeMandatory(item_id) {
      console.log(item_id);
      if (this.allGradeItemsModels[item_id] == true) {
        this.gradeItemsData.forEach((item) => {
          if (item.item_id == item_id) item.is_mandatory = true;
        });
      } else {
        this.gradeItemsData.forEach((item) => {
          if (item.item_id == item_id) item.is_mandatory = false;
        });
      }
    },
    checkMandatoryForGrades(item_id) {
      let mandatory = false;
      let notMandatory = false;
      this.gradeItemsData.forEach((item) => {
        if (item.item_id == item_id) {
          if (item.is_mandatory === true || item.is_mandatory === 1) {
            mandatory = true;
          } else {
            notMandatory = true;
            // return false;
          }
        }
      });
      if (notMandatory == true) {
        this.allGradeItemsModels[item_id] = false;
        return this.allGradeItemsModels[item_id];
      } else {
        this.allGradeItemsModels[item_id] = mandatory;
        return this.allGradeItemsModels[item_id];
      }
    },

    // open add grade modal
    openAddGradesModal() {
      this.addGradeDialog = true;
    },

    // close add grade modal
    closeAddGradesDialog(dialog, save) {
      this.addGradeDialog = dialog;
      if (save == true) {
        this.gradeAdded = true;
        this.getGradesTotal();
        this.getAllGradesFeeplan();
        this.getGradesItems();
      }
    },

    /* open confirm delete grade dialog */
    openConfirmDialog(gradeId) {
      this.confirmDialog = true;
      this.deletedgradeId = gradeId;
    },

    /* close master dialog */
    masterCancel() {
      this.masterDialog = false;
      // this.masterItems = [];
      axios
        .get(this.getApiUrl + "/paymentplans/showmaster/" + this.planId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          let master = response.data.data.data;
          let selectedIds = [];

          master.forEach(function(object) {
            selectedIds.push(object.item_id);
          });
          this.masterItems = selectedIds;
        });
    },

    /* close grade dialog */
    gradeCancel() {
      this.detailsDialog = false;

      axios
        .get(this.getApiUrl + "/paymentplans/showgrades/" + this.planId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          let grades = response.data.data;

          let selectedGradeItemIds = [];
          grades.forEach(function(object) {
            selectedGradeItemIds.push(object.item_id);
          });

          let unique = [...new Set(selectedGradeItemIds)];
          this.gradeItems = unique;
        });
    },

    getPlanName() {
      axios
        .get(this.getApiUrl + "/fees/" + this.planId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.planName = response.data.data.en.title;
          this.confirm = response.data.data.confirmation;
          this.activate = response.data.data.default;
        });
    },

    idString(id) {
      if (id) {
        id = id.toString();
      }

      return id;
    },
    /* get data on Modal */
    getAllFeesItems(type) {
      if (type == "master") {
        axios
          .get(this.getApiUrl + "/paymentplans/getAllFeesItems", {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          })
          .then((response) => {
            this.allMasterItems = response.data.data;
          });
      } else if (type == "details") {
        axios
          .get(this.getApiUrl + "/paymentplans/getAllFeesItems", {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          })
          .then((response) => {
            this.allGradeItems = response.data.data;
          });
      }
    },

    /* get all grades */
    getAllGradesItems() {
      axios
        .get(this.getApiUrl + "/paymentplans/getAllGrades", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.allGradeItems = response.data.data;
        });
    },

    /* get data on master table */
    getMasterItems() {
      axios
        .get(this.getApiUrl + "/paymentplans/showmaster/" + this.planId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.masterItemsData = response.data.data.data;
          this.masterTotal = response.data.data.total;

          let master = this.masterItemsData;
          let selectedIds = [];

          master.forEach(function(object) {
            selectedIds.push(object.item_id);
          });
          this.masterItems = selectedIds;
        });
    },

    /* get all grades KG1, KG2 */
    // getAllGrades() {
    //   axios
    //     .get(this.getApiUrl + "/paymentplans/getAllGrades", {
    //       headers: {
    //         Authorization: "Bearer " + localStorage.token
    //       }
    //     })
    //     .then(response => {
    //       this.allGrades = response.data.data;
    //     });
    // },

    getAllGradesFeeplan() {
      axios
        .get(this.getApiUrl + "/paymentplans/getAllGrades/" + this.planId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.allGrades = response.data.data;
        });
    },

    /* activate datePicker on master table */
    activateDatePickerOnMaster(masterTableData) {
      var _this = this;
      masterTableData.forEach(function(row, i) {
        var id = "#" + row.id;
        $(function() {
          $(id).calendarsPicker({
            dateFormat: "dd/mm/yyyy",
            onSelect: function(date) {
              if (date[0]) {
                var due_date =
                  date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                _this.masterItemsData[i].due_date = due_date;
              } else {
                _this.masterItemsData[i].due_date = "";
              }
            },
          });
        });
      });
    },

    /* activate datePicker on grade table */
    activateDatePickerOnGrades(gradeTableData) {
      var _this = this;
      gradeTableData.forEach(function(object, i) {
        var id = "#" + object.due_date_id;
        $(function() {
          $(id).calendarsPicker({
            dateFormat: "dd/mm/yyyy",
            onSelect: function(date) {
              if (date[0]) {
                var due_date =
                  date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                _this.gradeItemsData[i].due_date = due_date;
              } else {
                _this.gradeItemsData[i].due_date = "";
              }
            },
          });
        });
      });
      this.gradeAdded = false;
    },

    /* get all headers for grade table */
    getGradeItemsSelected() {
      axios
        .get(
          this.getApiUrl + "/paymentplans/showItemsSelected/" + this.planId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.detailsHeaders = response.data.data;
          this.getGradeHeadersModels();
        });
    },

    /* get data in details table */
    getGradesItems() {
      axios
        .get(this.getApiUrl + "/paymentplans/showgrades/" + this.planId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.gradeItemsData = response.data.data;
          let grades = this.gradeItemsData;
          let selectedGradeItemIds = [];
          grades.forEach(function(object) {
            selectedGradeItemIds.push(object.item_id);
          });
          let unique = [...new Set(selectedGradeItemIds)];
          this.gradeItems = unique;

          if (this.gradeAdded == true) {
            if (this.gradeItemsData.length == 0) {
              this.gradeEditable = false;
            } else {
              this.gradeEditable = true;
            }
            var gradeTable = this.gradeItemsData;

            this.activateDatePickerOnGrades(gradeTable);
          }
          this.gradeAdded = false;
        });
    },
    deleteGradeFromFeePlan(grade_id) {
      this.loading = true;
      axios
        .get(
          this.getApiUrl +
            "/paymentplans/deleteGradeFromFeePlan/" +
            this.planId +
            "/" +
            grade_id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.gradeItemsData = response.data.data;
          let grades = this.gradeItemsData;
          this.getAllGradesFeeplan();
          this.getGradesItems();
          this.getGradesTotal();
          let selectedGradeItemIds = [];
          grades.forEach(function(object) {
            selectedGradeItemIds.push(object.item_id);
          });
          let unique = [...new Set(selectedGradeItemIds)];
          this.gradeItems = unique;

          this.confirmDialog = false;
          this.deletedgradeId = "";
          this.loading = false;
        });
    },

    /* get total for each grade */
    getGradesTotal() {
      axios
        .get(
          this.getApiUrl + "/paymentplans/getAllGradesTotals/" + this.planId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.gradeTotalHeader = response.data.data;
        });
    },

    openMasterDialog() {
      this.masterDialog = true;
      this.getAllFeesItems("master");
    },

    openGradeDialog() {
      this.detailsDialog = true;
      this.getAllFeesItems("details");
    },

    saveMaster() {
      if (this.masterItemsFormValid) {
        axios
          .post(
            this.getApiUrl + "/paymentplans/storemaster/" + this.planId,
            { masterItems: this.masterItems },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.masterItemsData = response.data.data.data;
            this.masterDialog = false;

            if (this.masterItemsData.length == 0) {
              this.masterEditable = false;
            } else if (this.editMasterBtnVisible) {
              this.masterEditable = true;
            }

            this.masterTotal = response.data.data.total;

            this.snackbar = true;
            this.snackColor = "success";
            this.snackText = "Data Updated";

            var masterTable = this.masterItemsData;
            this.activateDatePickerOnMaster(masterTable);
          });
      } else {
        this.$refs.addMasterItemsForm.validate();
      }
    },

    saveGrade() {
      if (this.gradeItemsFormValid) {
        axios
          .post(
            this.getApiUrl + "/paymentplans/storegrades/" + this.planId,
            { gradeItems: this.gradeItems },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.gradeItemsData = response.data.data;
            this.getGradesItems();

            this.getGradeItemsSelected();
            this.detailsDialog = false;

            if (this.gradeItemsData.length == 0) {
              this.gradeEditable = false;
            } else if (this.editMasterGradeBtnVisible) {
              // this.getGradeHeadersModels();
              setTimeout(() => {
                this.gradeEditable = true;
                var gradeTable = this.gradeItemsData;
                this.activateDatePickerOnGrades(gradeTable);
              }, 2000);
            }

            this.getGradesTotal();

            this.snackbar = true;
            this.snackColor = "success";
            this.snackText = "Data Updated";
          });
      } else {
        this.$refs.addGradeItemsForm.validate();
      }
    },

    editMaster() {
      this.masterEditable = true;
      var masterTable = this.masterItemsData;
      this.activateDatePickerOnMaster(masterTable);
    },

    editGrade() {
      this.gradeEditable = true;
      var gradeTable = this.gradeItemsData;
      this.activateDatePickerOnGrades(gradeTable);
    },

    updateMaster() {
      if (this.masterFormValid == true) {
        this.masterLoading = true;
        axios
          .post(
            this.getApiUrl + "/paymentplans/updateMaster/" + this.planId,
            {
              masterItemData: this.masterItemsData,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          // eslint-disable-next-line no-unused-vars
          .then((response) => {
            this.masterLoading = false;
            if (response.data.status.error == true) {
              this.snackbar = true;
              this.snackText = "Error in save data";
              this.snackColor = "error";
            }
            if (response.data.status.error == false) {
              this.masterItemsData = response.data.data.data;
              this.masterTotal = response.data.data.total;
              this.masterEditable = false;
              this.snackbar = true;
              this.snackText = "Data Updated";
              this.snackColor = "success";

              var masterTable = this.masterItemsData;
              this.activateDatePickerOnMaster(masterTable);
            }
          });
      } else {
        this.$refs.masterTableForm.validate();
      }
    },

    /* cancel update on master */
    cancelMasterUpdate() {
      this.getMasterItems();
      this.masterEditable = false;
    },

    updateGrade() {
      if (this.gradeFormValid == true) {
        this.gradeLoading = true;
        axios
          .post(
            this.getApiUrl + "/paymentplans/updateGrades/" + this.planId,
            {
              gradeItemData: this.gradeItemsData,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          // eslint-disable-next-line no-unused-vars
          .then((response) => {
            this.gradeLoading = false;
            if (response.data.status.error == true) {
              this.snackbar = true;
              this.snackText = "Error in save data";
              this.snackColor = "error";
            }
            if (response.data.status.error == false) {
              this.gradeItemsData = response.data.data;
              this.getGradesTotal();
              this.snackbar = true;
              this.snackText = "Data Updated";
              this.snackColor = "success";
              this.gradeEditable = false;
            }
          });
      } else {
        this.$refs.gradeTableForm.validate();
      }
    },

    /* cancel update on grade */
    cancelGradeUpdate() {
      this.getGradesItems();
      this.gradeEditable = false;
    },

    confirmPlan() {
      // confirm plan

      // to check if MasterTable have price = 0
      let masterzeroValue =
        this.masterItemsData.length > 0
          ? this.masterItemsData.find((item) => {
              return item.price == 0;
            })
          : false;

      // to check if gradeTable have price = 0
      let gradezeroValue =
        this.gradeAdded.length > 0
          ? this.gradeItemsData.find((item) => {
              return item.price == 0;
            })
          : false;

      // condition to check all value greater than zero on master and grade table
      if ((gradezeroValue || masterzeroValue) && this.confirm == 0) {
        this.snackbar = true;
        this.snackText = "Price should be greater than 0";
        this.snackColor = "error";

        this.editGrade();
        this.editMaster();
        setTimeout(() => {
          this.$refs.masterTableForm.validate();
          this.$refs.gradeTableForm.validate();
        }, 500);
      } else if (
        this.masterItemsData.length > 0 ||
        (this.gradeItemsData.length > 0 &&
          (this.confirm == 0 || this.confirm == null))
      ) {
        this.confirmLoading = true;
        axios
          .post(
            this.getApiUrl +
              "/fees/changePlanconfirmationStatus/" +
              this.planId,
            {},
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          // eslint-disable-next-line no-unused-vars
          .then((response) => {
            this.confirmLoading = false;
            if (response.data.status.error == true) {
              this.snackbar = true;
              this.snackText =
                "You Should fill all data related to this fee plan before confirm.";
              this.snackColor = "error";
              this.editGrade();
              this.editMaster();
              setTimeout(() => {
                this.$refs.masterTableForm.validate();
                this.$refs.gradeTableForm.validate();
              }, 500);
              if (this.gradeItemsData.length == 0) {
                this.gradeEditable = false;
              }
            } else {
              this.confirm = response.data.data.confirmation;
              this.activate = response.data.data.default;

              // this.activatePlan();

              this.snackbar = true;
              this.snackText = "Fee plan confirmed successfully";
              this.snackColor = "success";
              this.masterEditable = false;
              this.gradeEditable = false;
            }
          });
      } else if (this.confirm == 0) {
        this.snackbar = true;
        this.snackText = "Can't confirm empty plan";
        this.snackColor = "error";
        this.masterEditable = false;
        this.gradeEditable = false;
      }

      // unconfirm plan
      else if (this.confirm == 1) {
        this.confirmLoading = true;
        axios
          .post(
            this.getApiUrl +
              "/fees/changePlanconfirmationStatus/" +
              this.planId,
            {},
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          // eslint-disable-next-line no-unused-vars
          .then((response) => {
            this.confirmLoading = false;
            this.confirm = response.data.data.confirmation;
            this.activate = response.data.data.default;
            if (this.activate) {
              this.activatePlan();
            }
            this.snackbar = true;
            this.snackText = "Fee plan unconfirmed successfully";
            this.snackColor = "success";
            this.masterEditable = false;
            this.gradeEditable = false;
          });
      }
    },
    activatePlan() {
      this.activateLoading = true;
      axios
        .post(
          this.getApiUrl + "/fees/changePlandefaultStatus/" + this.planId,
          {},
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          this.activateLoading = false;
          if (response.data.status.error == true) {
            this.snackbar = true;
            this.snackText = response.data.status.message;
            this.snackColor = "error";
          }
          this.confirm = response.data.data.confirmation;
          this.activate = response.data.data.default;
        });
    },
    // getMasterData() {
    //   let id = "/" + localStorage.school;
    //   axios
    //     .get(this.getApiUrl + "/school/showSchoolMasterData" + id, {
    //       headers: {
    //         Authorization: "Bearer " + localStorage.token
    //         //the token is a variable which holds the token
    //       }
    //     })
    //     .then(response => {
    //       // console.log(response.data.data.authourization_number !== undefined);
    //       if (response.data.data.authourization_number !== undefined)
    //         this.masterData = response.data.data;
    //     });
    // },

    printPage() {
      axios
        .get(this.getApiUrl + "/paymentplans/printPdfFee/" + this.planId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          var link = response.data.data;
          window.open(link, "_blank");
        });
    },
  },
  mounted() {
    this.planId = this.$router.currentRoute.params.id;
    this.getPlanName();
    this.getMasterItems();
    this.getGradesItems();
    // this.getAllGrades();
    this.getAllGradesFeeplan();
    this.getGradeItemsSelected();
    this.getGradesTotal();
    // this.getMasterData();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

.activate-btns {
  margin-top: 5px !important;
}

table {
  border-collapse: separate;
}
td {
  border: solid 1px #ffffff;
  // vertical-align: baseline;
}
tr:first-child td:first-child {
  border-top-left-radius: 10px;
}
tr:first-child td:last-child {
  border-top-right-radius: 10px;
}
// tr:last-child td:first-child {
//   border-bottom-left-radius: 10px;
// }
// tr:last-child td:last-child {
//   border-bottom-right-radius: 10px;
// }

.form-control {
  border: 1px solid $main-color;
  border-radius: 0;
}

.table th,
.table td {
  border-top: 0 !important;
}

.master {
  .masterTitle,
  .gradeTitle {
    .header {
      margin-bottom: 0 !important;
      margin-top: 12px;
    }
  }
}
hr {
  width: 100%;
  padding: 0;
  margin: 0;
}
.v-dialog {
  overflow-y: inherit;
}

.master-table {
  .header {
    padding: 20px 0;
  }
  tr.data-row {
    background-color: #ffffff !important;
    // border-radius: 23px !important;
    // box-shadow: 0px 3px 17px #d1d1d1 !important;
  }
  tr {
    background-color: transparent !important;
    box-shadow: unset !important;
    border-radius: 0 !important;

    td {
      border: 0;
      border-top: 0;
    }

    .master_items {
      width: 200px !important;
      color: $main-color;
    }
  }
}
.background-white {
  background-color: #ffffff !important;
  height: 198px !important;
  overflow: hidden;
}

.grade-header {
  // background-color: #fcfcfc !important;
}

.details-table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  // background-color: #ffffff;
  td {
  }
  .header {
    padding: 20px 0;
    height: 116px;
  }

  tr.header-row {
    td {
      border: 0 !important;
    }
  }

  tr {
    overflow-x: auto;
    // background-color: transparent !important;
    box-shadow: unset !important;
    border-radius: 0 !important;
  }
  tr.data-row {
    // background-color: #ffffff !important;
    // border-radius: 23px !important;
    // box-shadow: 0px 3px 17px #d1d1d1 !important;
  }
  th {
    max-width: 200px !important;
  }

  td.td-item {
    max-width: 200px !important;
    border: 0px;
    width: 200px;
  }
  td.grade_cell {
    width: 100px !important;
    background-color: #fff;
    border: 1px solid #eee7e7;
    height: 198px !important;
    overflow: hidden;

    .grade-due-date {
      padding-top: 0;
    }
  }
}

.add-icon {
  margin-top: 20px;
}

.due_date {
  .date-icon {
    font-size: 16px;
    padding: 0 7px;
  }
}

.confirm,
.activate {
  margin-right: 20px;
}

div#masterTitle {
  margin-top: 12px;
}

.cancel-edit {
  margin-right: 10px;
}

p {
  margin-bottom: 0 !important;
}
.grades-itemsgrid {
  padding-right: 0;
}

.total-container {
  padding-left: 0 !important;
  margin-left: 0;
  .total-table {
    overflow-x: hidden !important;
    td {
      width: 100px;
      border: 0 !important;
      // display: block;
    }
  }
}
.total-container td {
  border: 0 !important;
}
.totalHeight {
  height: 100px;
}
.icon-section {
  margin-top: 7px;
}

.edit-icon {
  margin-right: 20px;
}
// *:empty {
//   display: none !important;
// }
</style>
